import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import GdoImg from '../../../images/gdo.jpg'

const paginaServizio = {
  titolo: 'GDO',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: GdoImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Le consegne presso la grande distribuzione organizzata (GDO) o i centri logistici richiedono una gestione particolare a causa degli orari e delle restrizioni imposte dai destinatari.',
        },
        {
          id: 2,
          testo:
            'Scegliendo GDO-Service, potrai organizzare il flusso delle tue spedizioni senza perdite di tempo, con la consegna previo appuntamento.',
        },
        {
          id: 3,
          testo:
            'Gli accordi sulla prenotazione possono essere presi sia dal cliente in fase di richiesta del servizio che dalla Sede di destinazione prima della gestione della consegna. Verifica con la sede modalità e tempi per la prenotazione.',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo: 'Gestione automatica della prenotazione',
        },
        {
          id: 2,
          testo: 'Ottimizza i tempi',
        },
        {
          id: 3,
          testo:
            'Migliora la reperibilità dei destinatari e riduce eventuali rischi di giacenza',
        },
        {
          id: 4,
          testo: 'Controllo totale della spedizione',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
      ],
      servizi: null,
    },
  ],
}

const Gdo = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Gdo
